@tailwind base;
@tailwind components;
@tailwind utilities;

.dark {
  --background: #121212;
  --foreground: #ffffff;
}

.MuiListItemText-secondary {
  color: gray !important;
}

@layer base {
  :root {
    --radius: 0.5rem;
  }
}

.special {
  column-count: 2;
}
.MuiTypography-body1 {
  line-height: 1.2rem;
}

@font-face {
  font-family: "Jameel Noori Nastaleeq";
  src: url("/fonts/JameelNooriNastaleeq.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
